<template>
  <button @click.prevent="recaptcha">{{ btnText }}</button>
</template>

<script>
export default {
  name: 'recaptcha',
  props: {
    btnText: {
      type: String,
      default: 'Continue',
    },
    action: {
      type: String,
      required: true,
    },
  },
  methods: {
    async recaptcha() {
      await this.$recaptchaLoaded();
      const token = await this.$recaptcha(this.action);

      document.getElementById('recaptcha_token').value = token;

      this.$emit('token-set');
    },
  },
};
</script>
