<template>
  <div>
    <illustration name="boyGirl2"></illustration>
    <div>
      <div class="title align-center font-heading-30">
        What type of an account are you signing up for?
      </div>

      <div class="account-types">
        <div
          class="type type--parent font-body-normal"
          @click="$emit('parent-account-chosen')"
        >
          <div class="circle circle--parent"><i class="fal fa-family-pants icon fa-lg" /></div>
          <span>Parent account</span>
          <i class="fal fa-arrow-right icon fa-md icon-arrow" />
        </div>

        <a
          class="type type--provider font-body-normal"
          href="/register/provider"
        >
          <div class="circle circle--provider"><i class="fal fa-school icon fa-lg" /></div>
          <span>Provider account</span>
          <i class="fal fa-arrow-right icon fa-md icon-arrow" />
        </a>
      </div>
      <div class="to-kinside align-center">
        <a class="font-body-normal" href="/">Go back to kinside.com</a>
      </div>
    </div>
  </div>
</template>

<script>
import Illustration from '@components/illustration.vue';

export default {
  name: 'select-user-type',
  components: {
    Illustration,
  },
};
</script>

<style lang="scss" scoped>
.illustration.boyGirl2 {
  left: 0;
  right: 0;
  position: absolute;
  transform: translate(0%, -100%);
}

.title.font-heading-30 {
  font-weight: 500;
  padding-top: calc(var(--grid-unit) * 3);
}

.to-kinside {
  margin-top: calc(var(--grid-unit) * 3);
}

.account-types {
  .type {
    height: calc(var(--grid-unit) * 9);
    padding: calc(var(--grid-unit) * 2) calc(var(--grid-unit) * 3);
    border-radius: calc(var(--grid-unit) * 3);
    display: flex;
    cursor: pointer;
    max-width: 344px;
    margin: auto;
    margin-top: calc(var(--grid-unit) * 3);
    border: 1px solid var(--gray-15);
    color: var(--gray-80);

      &--parent {
        &:hover {
          background-color: var(--periwinkle-0);
          border: 1px solid var(--periwinkle-15);
        }
      }

      &--provider {
        &:hover {
          background-color: var(--coral-0);
          border: 1px solid var(--coral-15);
        }
      }

    span {
      margin-left: var(--grid-unit);
      margin-top: var(--grid-unit);
      flex: 1;

      @media screen and (max-width: 360px) {
        margin-top: 0;
      }
    }

    @media screen and (max-width: 400px) {
      padding: calc(var(--grid-unit) * 2) calc(var(--grid-unit) * 2);
    }
  }
}

.circle {
  display: inline-block;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  text-align: center;
  line-height: 2em;

  &--parent {
    background-color: var(--periwinkle-5);

    i {
      color: var(--periwinkle-30);
    }
  }

  &--provider {
    background-color: var(--coral-5);

    i {
      color: var(--coral-30);
    }
  }
}

.icon-arrow {
  color: var(--gray-40);
  margin-block: auto;
}
</style>
