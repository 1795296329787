<template>
  <div>
    <illustration name="boyGirl2"></illustration>
    <div>
      <div class="align-center font-heading-30">
        What type of an account are you signing up for?
      </div>

      <div class="account-types">
        <div class="type personal font-body-normal"
             @click="$emit('account-type-selected', 'personal');">
          <div class="circle"><i class="fal fa-person icon fa-xl"></i></div>
          <span>A personal account</span>
          <i class="fal fa-arrow-right icon fa-md icon-arrow" />
        </div>

        <div class="type work-sponsored font-body-normal"
             @click="$emit('account-type-selected', 'work_sponsored');">
          <div class="circle"><i class="fal fa-building icon fa-xl"></i></div>
          <span>A work-sponsored account</span>
          <i class="fal fa-arrow-right icon fa-md icon-arrow" />
        </div>
      </div>
      <div class="to-kinside align-center">
        <a v-if="!isFirstStep" class="font-body-normal" @click="$emit('back-to-user-type')">
          Go back
        </a>
        <a v-else class="font-body-normal" href="/">Go back to kinside.com</a>
      </div>
    </div>
  </div>
</template>

<script>
import Illustration from '@components/illustration.vue';

export default {
  name: 'select-account-type',
  components: {
    Illustration,
  },
  props: {
    isFirstStep: Boolean,
  },
};
</script>

<style lang="scss" scoped>
  .illustration.boyGirl2{
    left: 0;
    right: 0;
    position: absolute;
    transform: translate(0%, -100%);
  }

  .font-heading-30 {
    font-weight: 500 !important;
    padding-top: calc(var(--grid-unit) * 3);
  }

  .to-kinside {
    a {
      color: var(--viridian-50) !important;
      padding-left: var(--grid-unit);
      text-decoration: auto;
      cursor: pointer;
    }

    margin-top: calc(var(--grid-unit) * 3);
  }

  .account-types {
    .type {
      height:  calc(var(--grid-unit) * 9);
      padding: calc(var(--grid-unit) * 2) calc(var(--grid-unit) * 3);
      border-radius: calc(var(--grid-unit) * 3);
      display: flex;
      cursor: pointer;
      max-width: 344px;
      margin: auto;
      margin-top: calc(var(--grid-unit) * 3);
      border: 1px solid var(--gray-15);

      &:hover {
        background-color: var(--periwinkle-0);
        border: 1px solid var(--periwinkle-15);
      }

      span {
        margin-left: var(--grid-unit);
        margin-top: var(--grid-unit);
        flex: 1;

        @media screen and (max-width: 360px) {
          margin-top: 0;
        }
      }

      @media screen and (max-width: 400px) {
        padding: calc(var(--grid-unit) * 2) calc(var(--grid-unit) * 2);
      }
    }
   }

  .circle {
    display: inline-block;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    text-align: center;
    line-height: 2em;
    background-color: var(--periwinkle-5);

    i {
      color: var(--periwinkle-40);
    }
  }

  .icon-arrow {
    color: var(--gray-40);
    margin-block: auto;
  }
</style>
