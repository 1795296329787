<template>
  <div
    class="modal fade login-signup-modal register-new-parent-vue"
    data-backdrop="static"
    id="registrationModal"
    tabindex="-1"
    role="dialog"
    aria-hidden="true"
  >
    <div class="modal-dialog" role="document">
      <button
        v-if="consumerFromFacility"
        type="button"
        class="modal-close green"
        data-dismiss="modal"
        aria-label="Close"
        @click="onModalClose"
      >
        <span class="fal fa-times" />
      </button>

      <div class="modal-content">
        <div class="modal-body">
          <div v-if="step === '-1'">
            <select-user-type @parent-account-chosen="step = '0'" />
          </div>
          <div v-else-if="step === '0'">
            <select-account-type
              :is-first-step="verifyStep === '0'"
              @account-type-selected="showRegisterForm"
              @back-to-user-type="step = '-1'"
            />
          </div>
          <div v-else-if="step === '1'" class="js-parent-signup-form">
            <div class="login register-new-parent-vue">
              <div class="login__content">
                <illustration v-if="consumerFromFacility" name="girl"></illustration>
                <h4 :class="consumerFromFacility ? 'align-center mt-4' : 'mb-4'">{{title}}
                  <span></span>
                </h4>
                <p v-if="consumerFromFacility"
                   class="card-body--login__footer-text align-center">Already have an account?
                  <a href="#" @click.prevent="switchModalToLogin">Sign in</a>
                </p>
                <form id="parent-registartion-form"
                      data-track="signup_form" action="/users"
                      accept-charset="UTF-8"
                      data-remote="true"
                      method="post"
                      class="js-track-submit">
                  <div class="full-name-row">
                    <k-form-field
                        label="Parent first name"
                        rules="required"
                        class="k-form-field__name"
                    >
                      <k-input
                          name="user[first_name]"
                          v-model="firstName"
                      />
                    </k-form-field>
                    <k-form-field
                        label="Parent last name"
                        rules="required"
                        class="k-form-field__name"
                    >
                      <k-input
                          name="user[last_name]"
                          v-model="lastName"
                      />
                    </k-form-field>
                  </div>

                  <k-form-field v-if="employee"
                      label="Employer"
                      rules="required"
                      class="form-field"
                  >
                    <k-input
                        name="user[requested_company_name]"
                        v-model="requestedCompanyName"
                        class="w-100"
                    />
                  </k-form-field>

                  <k-form-field
                      :label="employee ? 'Work email' : 'Email'"
                      class="form-field"
                      :rules="{email: true, required: true}"
                  >
                    <k-input
                        name="user[email]"
                        v-model.trim="email"
                        class="w-100 js-sign-up-email"
                        :ref="email"
                    >
                    </k-input>
                  </k-form-field>

                  <k-form-field v-if="employee"
                                label="If you have an access code, enter it here."
                                class="form-field"
                  >
                    <k-input
                        name="user[companyAccessCode]"
                        v-model="companyAccessCode"
                        class="w-100"
                    />
                  </k-form-field>

                  <k-form-field
                      v-if="!consumerFromFacility"
                      label="Phone number"
                      rules="required|mobileNumber"
                      class="k-form-field__phone-number"
                  >
                    <k-input
                        name="user[phone_number]"
                        rules="required"
                        v-model="phoneNumber"
                        class="w-100"
                    />
                  </k-form-field>

                  <k-form-field label="Password" class="form-field" :rules="'required'">
                    <k-input
                      name="user[password]"
                      type="password"
                      v-model.trim="password"
                      class="w-100"
                      :ref="password"
                    >
                    </k-input>
                  </k-form-field>
                  <k-form-field label="Confirm password" class="form-field" :rules="'required'">
                    <k-input
                      name="user[password_confirmation]"
                      v-model.trim="passwordConfirmation"
                      class="form-field__input w-100"
                      type="password"
                      autocomplete="new-password"
                    >
                    </k-input>
                  </k-form-field>

                  <k-form-field>
                    <label
                      class="r-modal__label r-modal__label--checkbox r-modal__label--checkbox-terms"
                    >
                      <k-checkbox name="user[agree]" value="true" v-model="acceptCheckbox">
                        <span id="terms_label"
                          >I accept Kinside's
                          <a href="/terms-of-service" target="_blank">Terms and Conditions</a>
                        </span>
                      </k-checkbox>
                    </label>
                  </k-form-field>

                  <div class="js-errors"></div>

                  <box color="rust" class="mt-2 mb-2" v-if="error.length > 0 || errorUnknown">
                    <p>{{ error }}</p>
                    <p v-if="errorUnknown">
                      Something went wrong. Please contact us directly via
                      <a href="mailto:support@kinside.com">support@kinside.com</a>
                    </p>
                  </box>

                  <div class="text-center">
                    <input name="recaptcha_token"
                           type="hidden"
                           id="recaptcha_token"
                           autocomplete="off"
                    />
                    <recaptcha action="user_registration"
                               :btn-text="submitText()"
                               class="button--cta js-sign-up-submit js-track-click"
                               data-track="submit_signup_form"
                               :disabled="submitDisabled"
                               @token-set="submitForm"
                               id="submit-btn"/>
                  </div>

                  <div v-if="signupFrom === 'parents'" class="switch-form">
                    <div class="title">{{footerText[0]}}</div>
                    <div class="action" @click="toggleAccountType">{{footerText[1]}}</div>
                  </div>
                </form>
                <slot></slot>
              </div>
            </div>
          </div>
          <div v-else>
            <verify-account :email="this.email"></verify-account>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { axios, regexPatterns } from '@utils';
import KFormField from '@components/forms/form_field.vue';
import KInput from '@components/inputs/input.vue';
import Illustration from '@components/illustration.vue';
import Recaptcha from '@components/recaptcha.vue';
import KCheckbox from '@components/inputs/checkbox.vue';
import Box from '@components/box.vue';
import VerifyAccount from './verify-account.vue';
import SelectAccountType from './select-account-type.vue';
import SelectUserType from './select-user-type.vue';

const ProfileRegistrationSources = ['facility_profile', 'payment', 'pay_me_request'];

export default {
  name: 'parent-register',
  components: {
    KFormField,
    KInput,
    Illustration,
    KCheckbox,
    Recaptcha,
    Box,
    VerifyAccount,
    SelectAccountType,
    SelectUserType,
  },
  props: {
    verifyStep: {
      type: String,
      default: '1',
    },
    verifyEmail: {
      type: String, default: '',
    },
    title: {
      type: String,
      default: 'Create an account ',
    },
    signupFrom: String,
    verifyFirstName: {
      type: String,
      required: false,
    },
    verifyLastName: {
      type: String,
      required: false,
    },
    skipVerifyAccount: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      email: this.verifyEmail,
      firstName: this.verifyFirstName || '',
      lastName: this.verifyLastName || '',
      phoneNumber: '',
      password: '',
      passwordConfirmation: '',
      loading: false,
      acceptCheckbox: false,
      emailRegex: regexPatterns.email.regex,
      error: '',
      errorUnknown: false,
      step: this.verifyStep,
      accountType: 'personal',
      companyAccessCode: '',
      requestedCompanyName: '',
    };
  },
  watch: {
    verifyStep(newVal, oldVal) {
      if (oldVal !== '2') {
        this.step = newVal;
      }
    },
    verifyEmail(newVal) {
      this.email = newVal;
    },
    verifyFirstName(newVal) {
      this.firstName = newVal;
    },
    verifyLastName(newVal) {
      this.lastName = newVal;
    },
  },
  mounted() {
    if (!ProfileRegistrationSources.includes(this.signupFrom) && this.signupFrom !== 'parents') {
      this.accountType = 'work_sponsored';
    }
    if (this.$route.query.email && this.$route.query.step === '2') {
      $('#registrationModal').modal('show');
    }
    if (this.$route.query.open_waitlist_form) {
      $('#registrationModal .title span').html('to join a wailist');
      $('#registrationModal').modal('show');
    }
  },
  methods: {
    onModalClose() {
      this.password = '';
      this.passwordConfirmation = '';
      this.loading = false;
      this.acceptCheckbox = false;
      this.error = '';
      this.errorUnknown = false;
      $('.error-message').empty();
      this.phoneNumber = '';
      this.companyAccessCode = '';
      this.requestedCompanyName = '';
      if (!this.consumerFromFacility && this.$route.path !== '/confirm-account') {
        this.step = this.verifyStep;
      }
      if (!this.consumerFromFacility) {
        this.email = '';
        this.firstName = '';
        this.lastName = '';
      }
    },
    submitForm() {
      this.errorUnknown = false;
      this.error = '';
      if (!this.loading) {
        this.loading = true;
        axios.post(
          '/users',
          {
            user: {
              email: this.email.toLowerCase(),
              password: this.password,
              password_confirmation: this.passwordConfirmation,
              parent_signup: 'true',
              signup_from: this.signupFrom,
              agree: this.acceptCheckbox,
              first_name: this.firstName,
              last_name: this.lastName,
              phone_number: this.phoneNumber,
              account_type: this.accountType,
              company_access_code: this.companyAccessCode,
              requested_company_name: this.requestedCompanyName,
            },
            action_after_login: $('#registrationModal').data('action-after-login'),
            verify_account_required: this.skipVerifyAccount ? 'false' : 'true',
            redirect_to: this.$route.path,
            resp_format: 'json',
          },
        ).then((response) => {
          if (response.data.success && response.status === 200) {
            this.step = '2';
          } else if (response.data.message.length > 0) {
            this.error = response.data.message;
          } else {
            this.errorUnknown = true;
          }
          this.loading = false;
        }).catch(() => {
          this.loading = false;
          this.errorUnknown = true;
        });
      }
    },
    switchModalToLogin() {
      this.$emit('navigate-to-login', true);
    },
    submitText() {
      return this.consumerFromFacility ? 'Save and continue' : 'Sign up';
    },
    showRegisterForm(accountType) {
      this.accountType = accountType;
      this.step = '1';
    },
    toggleAccountType() {
      if (this.accountType === 'personal') {
        this.accountType = 'work_sponsored';
      } else {
        this.companyAccessCode = '';
        this.requestedCompanyName = '';
        this.accountType = 'personal';
      }
    },
  },
  computed: {
    submitDisabled() {
      return (
        this.firstName.length === 0
        || this.lastName.length === 0
        || this.email.length === 0
        || !this.emailRegex.test(this.email)
        || this.password.length === 0
        || this.passwordConfirmation.length === 0
        || !this.acceptCheckbox
        || (!this.consumerFromFacility && this.phoneNumber.length === 0)
      );
    },
    consumer() {
      return this.accountType === 'personal' && !ProfileRegistrationSources.includes(this.signupFrom);
    },
    employee() {
      return this.accountType === 'work_sponsored';
    },
    consumerFromFacility() {
      return this.accountType === 'personal' && ProfileRegistrationSources.includes(this.signupFrom);
    },
    footerText() {
      if (this.employee) {
        return ['Signing up for a personal account instead?', 'Sign up here'];
      }
      return ['Is your employer paying for Kinside?', 'Activate membership'];
    },
  },
};
</script>

<style lang="scss">
  /* stylelint-disable-next-line selector-id-pattern */
  #registrationModal {
    background-color: var(--modal-bg-color);

    .modal-content {
      border: 0;
      box-shadow: 0 16px 16px -12px rgba(0, 63, 51, 0.08);
    }

    .modal-close {
      @media screen and (max-width: 460px) {
        position: fixed;
        top: 10px;
        right: 10px;
        border: 0;
        background: transparent;
      }
    }

    .full-name-row {
      margin-top: calc(var(--grid-unit) * 3);
      margin-bottom: calc(var(--grid-unit) * 2);
      display: flex;

      .k-form-field__name {
        margin: 0 !important;
      }

      :first-child {
        padding-right: var(--grid-unit) !important;
      }
    }

    .react-code-input-container {
      width: auto !important;

      input {
        border-radius: var(--grid-unit) !important;
        margin-right: calc(var(--grid-unit) * 3) !important;
        border-right: 1px solid #3B3B3B !important;
        border: 1px solid #3B3B3B !important;
        border-top-left-radius: var(--grid-unit) !important;
        border-bottom-left-radius: var(--grid-unit) !important;
        font-family: var(--sans-serif);

        @media screen and (max-width: 400px) {
          margin-right: var(--grid-unit) !important;
        }
      }
    }

    .react-code-input-container.error {
      input {
        color: var(--rust-40) !important;
        border: 1px solid var(--rust-40) !important;
      }
    }

    .switch-form {
      .title {
        font-weight: 500;
        text-align: center;
        color: var(--gray-80);
        margin-top: calc(var(--grid-unit) * 3);
      }

      .action {
        font-weight: 400;
        text-align: center;
        color: var(--viridian-60);
        cursor: pointer;
      }
    }
  }
</style>
