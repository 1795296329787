<template>
  <div class="verify-account">
    <illustration class="register" name="mailbox"></illustration>
    <div class="align-center">
      <div class="font-heading-30 mt-2">
        One last thing!
      </div>
      <p class="font-body-normal">We sent an email with a verification code to {{ email }}</p>
      <p class="font-body-normal">This code will expire in 15 minutes.</p>
      <div class="font-body-large mt-3">Enter your 5-digit code here:</div>
      <div class="mt-2">
        <vue-verification-code-input :fieldHeight="40" :fieldWidth="36"
                                     ref='code'
                                     :class="inCorrectCode ? 'error' : '' "  @complete="onComplete"
                                     @change="onChange"
                                     v-model="verificationCode" :fields="5"/>
        <p class="mt-1 error-message" v-if="inCorrectCode">
          {{ inCorrectCodeMessage}}
        </p>
        <button
            @click.prevent="verifyAccount"
            :disabled="!completed"
            class="mt-3 btn btn-md btn-primary">
          Continue
        </button>
      </div>
      <div class="resend-verification">
        <p>Didn't get it?
          <span>
            <a @click.prevent="resendVerificationCode"
               href="#">Request new code.</a>
          </span>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import Illustration from '@components/illustration.vue';
import { axios } from '@utils';
import VueVerificationCodeInput from 'vue-verification-code-input';

export default {
  name: 'verify-account',
  components: {
    Illustration,
    VueVerificationCodeInput,
  },
  props: {
    email: String,
  },
  data() {
    return {
      verificationCode: '',
      completed: false,
      inCorrectCodeMessage: '',
      inCorrectCode: false,
    };
  },
  methods: {
    onChange(value) {
      this.completed = value.length === 5;
    },
    verifyAccount() {
      this.inCorrectCode = false;
      axios.put('/verify_parent.json', { email: this.email.toLowerCase(), verification_code: this.verificationCode }).then((response) => {
        if (response.data.success && response.status === 200) {
          if (this.$route.path.includes('/childcare') && !this.$route.path.endsWith('/pay')) {
            window.location.href += this.$route.query.open_waitlist_form
              ? ''
              : `?action_after_login=${$('#registrationModal').data('action-after-login')}`;
          } else if (this.$route.path.endsWith('/pay')) {
            if (this.$route.query.is_checkout === 'true') {
              window.location.href = '/payments';
            } else if (this.$route.query.pr_id) {
              window.location.href = `${this.$route.path}/?pr_id=${this.$route.query.pr_id}`;
            } else {
              window.location.href = this.$route.path;
            }
          } else {
            window.location.href = '/';
          }
        } else if (response.data.message.length > 0) {
          this.inCorrectCode = true;
          this.inCorrectCodeMessage = response.data.message;
        }
      });
    },
    onComplete(value) {
      this.verificationCode = value;
      this.completed = true;
      this.inCorrectCode = false;
    },
    async resendVerificationCode() {
      this.inCorrectCode = false;
      const redirectTo = this.$route.path.endsWith('/pay') ? '/payments' : this.$route.path;
      axios.put('/request_new_verification_code.json', { email: this.email.toLowerCase(), redirect_to: redirectTo }).then((response) => {
        if (response.data.success) {
          this.verificationCode = '';
          this.$refs.code.values = ['', '', '', '', ''];
          this.completed = false;
        } else {
          this.inCorrectCode = true;
          this.inCorrectCodeMessage = response.data.message;
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.btn-primary:disabled {
  background-color: var(--viridian-50) !important;
  border-color: var(--viridian-50) !important;
}

.btn-primary:hover {
  color: #fff;
  background-color: var(--viridian-50) !important;
  border-color: var(--viridian-50) !important;
}

.error-message {
  color: var(--rust-40);
}

.align-center {
  color: var(--gray-80);
}

.resend-verification {
  a {
    color: var(--viridian-50) !important;
    padding-left: var(--grid-unit);
    text-decoration: auto;
  }

  font-size: var(--font-size-10);
  line-height: calc(var(--grid-unit) * 3);
  margin-top: calc(var(--grid-unit) * 2);
}

.register {
  background: none !important;
}

.verify-account {
  font-weight: 400;
}
</style>
